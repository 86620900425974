import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { Modal } from "common/core/ui/dialogs/modal.service";
import { PrivacyInputModalComponent } from "./privacy-input-modal/privacy-input-modal.component";
import { PrivacyModalComponent } from "./privacy-modal/privacy-modal.component";
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(auth, socialAuth, settings, user, router, toast, bootstrapper, recaptcha, modal) {
        this.auth = auth;
        this.socialAuth = socialAuth;
        this.settings = settings;
        this.user = user;
        this.router = router;
        this.toast = toast;
        this.bootstrapper = bootstrapper;
        this.recaptcha = recaptcha;
        this.modal = modal;
        this.model = {};
        this.errors = {};
        this.isLoading = false;
        this.isFirst = true;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        this.model.privacy_read = true;
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
    };
    RegisterComponent.prototype.register = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        _a = this.recaptcha.enabledFor('registration');
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recaptcha.verify('registration')];
                    case 1:
                        _a = !(_b.sent());
                        _b.label = 2;
                    case 2:
                        if (_a) {
                            this.isLoading = false;
                            return [2 /*return*/, this.toast.open('Could not verify you are human.')];
                        }
                        if (this.model.privacy !== true || this.model.privacy_read !== true) {
                            this.isLoading = false;
                            return [2 /*return*/, this.toast.open('Please read and accept goMusix\'s Privacy Policy.')];
                        }
                        this.auth.register(this.model).subscribe(function (response) {
                            _this.isLoading = false;
                            if (_this.settings.get('require_email_confirmation')) {
                                _this.toast.open('We have sent you an email with instructions on how to activate your account.');
                            }
                            else {
                                _this.bootstrapper.bootstrap(response.data);
                                _this.router.navigate([_this.auth.getRedirectUri()]).then(function () {
                                    _this.toast.open('Registered successfully.');
                                });
                            }
                        }, function (response) {
                            _this.errors = response['messages'];
                            _this.isLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterComponent.prototype.showPrivacyInputModal = function () {
        var _this = this;
        if (!this.isFirst && this.model.privacy_read) {
            return true;
        }
        if (this.isFirst) {
            this.model.privacy_read = false;
            this.isFirst = false;
        }
        this.modal.show(PrivacyInputModalComponent).afterClosed().subscribe(function (isRead) {
            _this.model.privacy = false;
            if (!isRead)
                return;
            _this.model.privacy_read = isRead;
            _this.model.privacy = isRead;
        });
        if (!this.model.privacy_read) {
            this.model.privacy_read = false;
            this.model.privacy = false;
            return false;
        }
    };
    RegisterComponent.prototype.showPrivacyModal = function ($event) {
        $event.preventDefault();
        this.modal.show(PrivacyModalComponent);
        this.model.privacy_read = true;
        this.isFirst = false;
    };
    return RegisterComponent;
}());
export { RegisterComponent };
