<div class="privacy-modal" id="privacy-modal">
    <div class="backdrop"></div>

    <div class="modal-content">
        <div class="modal-header">
            <h1 style="text-align: center"> PRIVACY POLICY</h1>
            <div (click)="close()" class="close-modal-icon">
                <mat-icon svgIcon="close"></mat-icon>
            </div>
        </div>
        <div class="row">
            <iframe src="//privacy.gomusix.com/" width="100%" ></iframe>
            <button class="pull-right primary" (click)="close()"> Accept</button>
        </div>

    </div>
</div>

