<ad-host slot="ads.general_top" class="margin-top margin-bottom"></ad-host>

<filterable-page-header [filterQuery]="filterQuery" class="margin">
    <span trans>Top 50</span>
</filterable-page-header>

<track-list [tracks]="filteredItems" [showAlbum]="true" [showArtist]="true"></track-list>

<no-results-message *ngIf="!filteredItems || ! filteredItems.length">
    <span primary-text trans>No charts found.</span>
    <span secondary-text trans>Could not find charts. Please try again later.</span>
</no-results-message>

<ad-host slot="ads.general_bottom" class="margin-top margin-bottom"></ad-host>