/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./privacy-modal.component";
import * as i5 from "@angular/material/dialog";
var styles_PrivacyModalComponent = [i0.styles];
var RenderType_PrivacyModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PrivacyModalComponent, data: {} });
export { RenderType_PrivacyModalComponent as RenderType_PrivacyModalComponent };
export function View_PrivacyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "privacy-modal"], ["id", "privacy-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "backdrop"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 21, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" PRIVACY POLICY"])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "close-modal-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "iframe", [["src", "//privacy.gomusix.com/"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "pull-right primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Accept"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var currVal_2 = "close"; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 14).inline; var currVal_1 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_0, currVal_1); }); }
export function View_PrivacyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "privacy-modal", [], null, null, null, View_PrivacyModalComponent_0, RenderType_PrivacyModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.PrivacyModalComponent, [i5.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyModalComponentNgFactory = i1.ɵccf("privacy-modal", i4.PrivacyModalComponent, View_PrivacyModalComponent_Host_0, {}, {}, []);
export { PrivacyModalComponentNgFactory as PrivacyModalComponentNgFactory };
