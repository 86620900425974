import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'privacy-modal',
    templateUrl: './privacy-modal.component.html',
    styleUrls: ['./privacy-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PrivacyModalComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<PrivacyModalComponent>,
    ) {}

    ngOnInit() {}

    /**
     * Close modal and reset it to initial state.
     */
    public close() {
        this.dialogRef.close();
    }
}
