import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Modal} from "common/core/ui/dialogs/modal.service";
import {PrivacyModalComponent} from "./../privacy-modal/privacy-modal.component";

@Component({
    selector: 'privacy-input-modal',
    templateUrl: './privacy-input-modal.component.html',
    styleUrls: ['./privacy-input-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PrivacyInputModalComponent implements OnInit {
    public model: {
        privacy_read?: boolean,
    } = {};

    constructor(
        private dialogRef: MatDialogRef<PrivacyInputModalComponent>,
        private modal: Modal,
    ) {}

    ngOnInit() {}

    public closeReadModal() {
        this.model.privacy_read = true;
        this.dialogRef.close(true);

        const self = this;
        setTimeout(function () {
            self.modal.show(PrivacyModalComponent);
        }, 200)
    }

    /**
     * Close modal and reset it to initial state.
     */
    public close() {
        this.dialogRef.close(false);
    }
}
