/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-input-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./privacy-input-modal.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../core/ui/dialogs/modal.service";
var styles_PrivacyInputModalComponent = [i0.styles];
var RenderType_PrivacyInputModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PrivacyInputModalComponent, data: {} });
export { RenderType_PrivacyInputModalComponent as RenderType_PrivacyInputModalComponent };
export function View_PrivacyInputModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "privacy-input-modal"], ["id", "privacy-input-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "backdrop"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "close-modal-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                Please read goMusix's Privacy Policy before accept\n            "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "pull-right primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeReadModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Read"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_2 = "close"; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).inline; var currVal_1 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_PrivacyInputModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "privacy-input-modal", [], null, null, null, View_PrivacyInputModalComponent_0, RenderType_PrivacyInputModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.PrivacyInputModalComponent, [i5.MatDialogRef, i6.Modal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyInputModalComponentNgFactory = i1.ɵccf("privacy-input-modal", i4.PrivacyInputModalComponent, View_PrivacyInputModalComponent_Host_0, {}, {}, []);
export { PrivacyInputModalComponentNgFactory as PrivacyInputModalComponentNgFactory };
