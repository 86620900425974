<div class="privacy-input-modal" id="privacy-input-modal">
    <div class="backdrop"></div>

    <div class="modal-content">
        <div class="modal-header">
            <div (click)="close()" class="close-modal-icon"><mat-icon svgIcon="close"></mat-icon></div>
        </div>
        <div class="row">
            <h4>
                Please read goMusix's Privacy Policy before accept
            </h4>
            <button class="pull-right primary" (click)="closeReadModal()"> Read</button>
        </div>

    </div>
</div>
